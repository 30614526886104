<template>
  <div class="mediaContainer">
    <b-container class="shlajsna">
      <b-row>
        <b-col cols="12">
          <h1 class="text-center">{{ $t('images.download-from-image-library') }}</h1>
        </b-col>
        <b-col cols="12">
          <SearchAndFilterPublic @searchVideo="searchVideo" type="image" />
        </b-col>

        <b-col cols="12">
          <b-overlay :show="showOverlay">
            <MediaGrid :media="media" />
            <b-row v-if="loadMore === true">
              <b-col>
                <p class="text-center">
                  <b-button variant="primary" @click="loadMoreData" class="my-4" size="lg">
                    {{ $t('general.load-more') }} ({{ (page + 1) + "/" + totalPages }})
                  </b-button>
                </p>
              </b-col>
            </b-row>
          </b-overlay>
          <div v-if="!noResult">
            No result
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import SearchAndFilterPublic from '@/components/util/SearchAndFilterPublic.vue'
import MediaGrid from '@/components/util/MediaGrid.vue'
export default {
  name: "AllImagesPublic",
  components: { MediaGrid, SearchAndFilterPublic },
  data: function() {
    return {
      media: [],
      tags: [],
      showOverlay: false,
      loadMore: false,
      totalPages: 0,
      page: 0,
      noResult: true
    }
  },
  created: async function() {
    this.showOverlay = true;
     if (window.sessionStorage.getItem("imagePagination")) {
      this.media = this.decodeJSON(window.sessionStorage.getItem("imagePagination"));
      this.loadMore = (window.sessionStorage.getItem("imagePaginationHasNext") === "true");
      this.page = parseInt(window.sessionStorage.getItem("imagePaginationPage"));
      this.totalPages = parseInt(window.sessionStorage.getItem("imagePaginationTotalPages"));
    } else {
      this.setImageData()
    }
    this.showOverlay = false;
  },
  methods: {
    searchVideo: async function(filter, sort) {
      this.media = [];
      this.showOverlay = true;
      await this.$http.post("/api/media/search?sort=" + sort, filter).then((r) => {
        //console.log(r.data.content);
        this.media = r.data.content;
        this.loadMore = r.data.hasNext;
        this.noResult = r.data.hasContent;
      }).catch((e) => { console.log(e) });
      this.showOverlay = false;
    },

    loadMoreData: function() {
      this.page++;
      this.$http.get('/api/media/all/' + this.$root.lang.IANA + '/image/?page=' + this.page).then(r => {
        this.media = this.media.concat(r.data.content);
        this.loadMore = r.data.hasNext;
        window.sessionStorage.setItem("imagePagination", this.encodeJSON(this.media));
        window.sessionStorage.setItem("imagePaginationHasNext", r.data.hasNext);
        window.sessionStorage.setItem("imagePaginationPage", this.page);
        window.sessionStorage.setItem("imagePaginationTotalPages", r.data.totalPages);
      }).catch(e => {
        var message = this.handleError(e);
      // this.showOverlay = false;
      })
    },

    async setImageData() {
      this.showOverlay = true;
      await this.$http.get('/api/media/all/' + this.$root.lang.IANA + '/image/?page=' + this.page).then(r => {
      this.media = r.data.content;
        this.loadMore = r.data.hasNext;
        this.showOverlay = false;
        this.totalElements = r.data.totalElements;
        this.totalPages = r.data.totalPages;
        //console.log(r.data);
      }).catch(e => {
        var message = this.handleError(e);
      })        
      this.showOverlay = false;
    }
  },
  computed: {
    _lang: function() {
      return this.$root.lang
    }
  },
  watch:{
    _lang : async function() {
      this.setImageData();
    }
  }
}
</script>
<style></style>
